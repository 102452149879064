// extracted by mini-css-extract-plugin
export var S1 = "FourOFour-module---s1--4bd62";
export var S2 = "FourOFour-module---s2--8adb3";
export var S3 = "FourOFour-module---s3--b1fe1";
export var S4 = "FourOFour-module---s4--02525";
export var S5 = "FourOFour-module---s5--22ee9";
export var S6 = "FourOFour-module---s6--9960c";
export var V1 = "FourOFour-module---v1--4c9f1";
export var V10 = "FourOFour-module---v10--88747";
export var V11 = "FourOFour-module---v11--a2fdf";
export var V2 = "FourOFour-module---v2--ec228";
export var V3 = "FourOFour-module---v3--c045b";
export var V4 = "FourOFour-module---v4--511e3";
export var V5 = "FourOFour-module---v5--2a5a7";
export var V6 = "FourOFour-module---v6--81adb";
export var V7 = "FourOFour-module---v7--4630d";
export var V8 = "FourOFour-module---v8--947f5";
export var V9 = "FourOFour-module---v9--d42af";
export var bubble = "FourOFour-module--bubble--e4a1c";
export var cbParticles = "FourOFour-module--cbParticles--24de7";
export var cbParticlesItem = "FourOFour-module--cbParticles-item--9e9fb";
export var description = "FourOFour-module--description--bc139";
export var homeLink = "FourOFour-module--homeLink--1b1a5";
export var homeLinkBackground = "FourOFour-module--homeLinkBackground--789fe";
export var homeLinkWrapper = "FourOFour-module--homeLinkWrapper--db2fc";
export var pageNotfound = "FourOFour-module--pageNotfound--8c0ca";
export var title = "FourOFour-module--title--b7ced";
export var wrapper = "FourOFour-module--wrapper--197a0";