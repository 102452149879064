import {Link} from "gatsby";
import {useEffect} from "react";
import Att from "../assets/At&t.png";
import Disney from "../assets/Disney.png";
import Nike from "../assets/Nike.png";
import Paypal from "../assets/Paypal.png";
import Tesla from "../assets/Tesla.png";
import Uber from "../assets/Uber.png";
import Particles from "../library/particles";
import * as styles from "../scss/pages/FourOFour.module.sass";

export const FourOFour = () => {
  useEffect(() => {
    const demoParticles = document.querySelector(".cb-particles");
    const particles = new Particles({
      container: demoParticles,
      itemsSelector: ".cb-particles-item",
    });
  }, []);
  return (
    <div className={styles.wrapper}>
      <div className="cb-particles height-100vh">
        <div className="cb-particles-item -text -v1 -s4">
          <img src={Tesla} alt="Tesla" className={styles.bubble} />
        </div>
        <div className="cb-particles-item -text -v1 -s4">
          <img src={Tesla} alt="Tesla" className={styles.bubble} />
        </div>
        <div className="cb-particles-item -text -v2 -s4">
          <img src={Att} alt="Tesla" className={styles.bubble} />
        </div>
        <div className="cb-particles-item -text -v3 -s4">
          <img src={Paypal} alt="Paypal" className={styles.bubble} />
        </div>
        <div className="cb-particles-item -text -v4 -s4">
          <img src={Disney} alt="Tesla" className={styles.bubble} />
        </div>
        <div className="cb-particles-item -text -v5 -s4">
          <img src={Nike} alt="Paypal" className={styles.bubble} />
        </div>
        <div className="cb-particles-item -text -v6 -s4">
          <img src={Uber} alt="Tesla" className={styles.bubble} />
        </div>
        <div className="cb-particles-item -text -v7 -s4">
          <img src={Paypal} alt="Paypal" className={styles.bubble} />
        </div>
        <div className="cb-particles-item -text -v8 -s4">
          <img src={Tesla} alt="Tesla" className={styles.bubble} />
        </div>
      </div>
      <h1 className={styles.title}>404</h1>
      <p className={styles.pageNotfound}>Page not found</p>
      <p className={styles.description}>
        Oops! The page you are looking for does not exist. It might have been
        moved or deleted.
      </p>
      <div className={styles.homeLinkWrapper}>
        <Link to="/" className={styles.homeLink}>
          BACK TO HOME
        </Link>
        <div className={styles.homeLinkBackground} />
      </div>
    </div>
  );
};

export default FourOFour;